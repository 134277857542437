<template>
  <b-container fluid>
    <bo-page-title />

    <b-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit(submitTaskSetup())">
          <b-card-header>
            <h5 class="card-title">Setup Task</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md=3>
                <b-form-group>
                  <label for="pic">PIC <span class="text-danger mr5">*</span></label>
                  <v-select @input="setPicName($event)" v-model="row.tta_pic" placeholer="PIC" :options="userGa" :reduce="data => data.value" />
                  <VValidate mode="passive" name="PIC" v-model="row.tta_pic" :rules="mrValidation.tta_pic" />
                </b-form-group>
              </b-col>
              <b-col md=3>
                <b-form-group>
                  <label>Date<span class="text-danger mr5">*</span></label>
                  <template v-if="row.is_range_date == 'Y'">
                    <date-picker range type="date" value-type="format" format="YYYY-MM-DD"  prefix-class="crm" v-model="dateRange" :disabled-date="beforeToday" />
                    <VValidate name="Date" mode="passive" :rules="mrValidation.tta_start_date" v-model="dateRange" />
                  </template>
                  <template v-else>
                    <date-picker type="date" value-type="format" format="YYYY-MM-DD"  prefix-class="crm" v-model="row.tta_start_date" :disabled-date="beforeToday" />
                    <VValidate name="Date" mode="passive" :rules="mrValidation.tta_start_date" v-model="row.tta_start_date" />
                  </template>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="row.is_range_date"
                    name="checkbox-1"
                    value="Y"
                    unchecked-value="N"
                  >
                    End Date?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md=4 class="border-right">
                <div class="container-list">
                  <div class="container-list-title">
                    <h6 class="title">Task Item</h6>
                  </div>
                  <div class="container-list-dropdown">
                    <b-form-group>
                      <label>Category Task</label>
                      <v-select @input="changeCategory($event)" v-model="category" placeholer="Category" :options="mrCategory" :reduce="data => data.value" />
                    </b-form-group>
                  </div>
                  <div class="container-list-task-item">
                    <ul class="p-0" v-if="taskList.length">
                      <li class="mb-2">
                        <b-form-checkbox
                          id="all"
                          :name="'all'+category"
                          v-model="selectedAll"
                          value="Y"
                          @change="selectAll($event)"
                          unchecked-value="N"
                        >
                          <p class="task-item">Select All</p>
                        </b-form-checkbox>
                      </li>
                      <li v-for="(value, index) in taskList" :key="index">
                        <b-form-checkbox
                          :id="'checkbox'+value.mti_id"
                          v-model="value.selected"
                          :name="'checkbox'+value.mti_id"
                          value="Y"
                          @change="selectTask($event, value)"
                          unchecked-value="N"
                        >
                          <p class="task-item">{{value.mti_title}}</p>
                        </b-form-checkbox>
                      </li>
                    </ul>
                  </div>
                </div>
              </b-col>
              <b-col md=4>
                <div class="container-list">
                  <div class="container-list-title">
                    <h6 class="title">Selected Task Item</h6>
                    <VValidate mode="passive" name="Tasks" rules="required" v-model="selectedTask" />
                  </div>
                  <div class="container-list-task-item">
                    <div class="list-category" v-for="(ct, k) in selectedTask" :key="k">
                      <p class="title-category">{{ct.category}}</p>
                      <ol>
                        <li v-for="(task, k2) in ct.tasks" :key="`${k}${k2}`">
                          <p class="task-item">{{task.mti_title}}</p>
                        </li>
                      </ol>
                    </div>
                    <h4 v-if="selectedTask.length == 0">No tasks selected yet</h4>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="resetForm" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="primary" class="btn-rounded">Publish</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </ValidationObserver>
    </b-card>
  </b-container>
</template>
<style scoped>
.c-pointer {
  cursor: pointer;
}
.category-card .list-item {
  max-height: 450px;
  overflow-y: auto;
  padding-right: 20px;
}
.card.category-card li {
    list-style: none;
    border: 1px solid #c7c7c7;
    margin-bottom: 10px;
    padding: 8px 16px;
    cursor: pointer;
}
.container-list-title{
  margin-bottom: 1em;
}
.container-list-task-item ul {
  padding: 0 15px;
}
.container-list-task-item ul li {
  list-style: none;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
.title-category {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px;
}
.task-item {
  margin: 0 0 5px;
  font-size: 14px;
}
</style>
<script>
	import GlobalVue from '@/libs/Global.vue'
  import Gen from '@/libs/Gen.js'
  
  export default {
		extends: GlobalVue,
    data() {
      return {
        idKey: 'tbt_id',
        mrValidation: {},
        dataModal: {},
        dateRange: [],
        mrCategory: [],
        category: null,
        task: [],
        taskList: [],
        selectedTask: [],
        userGa: []
      }
    },
    mounted(){
      this.apiGet()
    },
    computed: {
      selectedAll(){
        return (this.category && this.taskList.every(v => v.selected == 'Y')) ? 'Y' : 'N'
      }
    },
    methods: {
      selectAll(event){
        for(let i = 0; i < this.taskList.length; i++){
          this.taskList[i].selected = event
          this.selectTask(event, this.taskList[i])
        }
      },
      resetForm(){
        this.selectedTask = []
        this.dateRange = []
        this.category = null
        this.taskList = []
        this.row = {}
        this.apiGet()
      },
      setPicName(event){
        const picName = this.userGa.find(v => v.value == event)
        if(picName){
          this.$set(this.row, 'tta_pic_name', picName.label)
        }
      },
      selectTask(event, item){
        const categoryName = this.mrCategory.find(v => v.value == item.mti_category)
        if(event == 'Y'){
          if(!this.selectedTask.length){
              this.selectedTask.push({ category:  categoryName.label || 'Category', category_id: categoryName.value, tasks: [ item ]})
          }else{
            const indexCategory = this.selectedTask.findIndex(v => v.category_id == item.mti_category)
            if(indexCategory > -1){
              this.selectedTask[indexCategory].tasks.push(item)
            }else{ 
              this.selectedTask.push({ category:  categoryName.label || 'Category', category_id: categoryName.value, tasks: [ item ]})
            }
          }
        }else{
          const indexCategory = this.selectedTask.findIndex(v => v.category_id == item.mti_category)
          if(indexCategory > -1){
            const taskList = this.selectedTask[indexCategory].tasks
            this.selectedTask[indexCategory].tasks = taskList.filter(v => v.mti_id != item.mti_id)
            if(this.selectedTask[indexCategory].tasks.length == 0){
              this.selectedTask.splice(indexCategory, 1)
            }
          }
        }
      },
      changeCategory(event){
        if(!event){
          this.taskList = []
        }else{
          this.taskList = this.task.filter(v => v.mti_category == event)
        }
      },
      submitTaskSetup(){
        console.log(this.$refs['VForm'])
        this.$refs['VForm'].validate().then(success => {
          console.log(success)
          if(!success) {
            return 
          }
          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })
          Gen.apiRest('/do/' + this.modulePage, { data: Object.assign({type: 'add' },  {...this.row, selectedTask: this.selectedTask, date: this.dateRange}) }, "POST").then(res => {
            this.$swal({
              title: res.data.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(()=> {
              this.resetForm()
            })
          }).catch(err=> {
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response.data.message
                err.title = err.response.data.title
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        })
      },
    },
    watch:{
      $route(){
        this.apiGet()
      },
    },
  }
</script>